import Plyr from 'plyr';

const player = new Plyr('#player', {
  'volume': 1,
  'debug': false,
  'autoplay': false,
  'captions': {
    active: true,
    language: 'auto',
    update: false
  },
  'controls': ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'pip', 'airplay'],
  'settings': ['captions', 'quality', 'speed', 'loop'],
  'keyboard': {
    focused: true,
    global: false
  },
  'tooltips': {
    controls: false,
    seek: true
  },
  'loadSprite': true
});

var closeForRest = false;

player.on('pause', event => {
  const instance = event.detail.plyr;

  if (!player.seeking) {
    $(".video_overlay[on_pause]").each(function () {
      info_layer.open($(this).val($(this)));
    });
  }
  if (player.fullscreen.active) {
    player.fullscreen.exit();
  }
});

player.on('ended', event => {
  const instance = event.detail.plyr;

  $(".video_overlay[on_stop]").each(function () {
    info_layer.open($(this).val($(this)));
  });
  if (player.fullscreen.active) {
    player.fullscreen.exit();
  }
});

player.on('play', event => {
  const instance = event.detail.plyr;
  closeForRest = false;

  $(".video_overlay").each(function () {
    info_layer.close($(this).val($(this)));
  });
});

player.on('timeupdate', event => {
  const instance = event.detail.plyr;
  if (closeForRest) {
    return
  };

  $(".video_overlay[start_time]").each(function () {
    info_layer.open_on_time(player.currentTime, $(this).val($(this)));
  });
});

$(".js_player_play").on('click', function (e) {
  info_layer.close($(this).closest('.video_overlay'));
  closeForRest = true;
  if (player.paused) {
    player.play();
  }
  e.preventDefault();
});

$(".js_player_pause").on('click', function (e) {
  player.pause();
  e.preventDefault();
});

$(".js_player_stop").on('click', function (e) {
  player.stop();
  e.preventDefault();
});

$(".js_close").on('click', function (e) {
  info_layer.close($(this).closest('.video_overlay'));
  closeForRest = true;
  if (player.paused && !player.ended) {
    player.play();
  }
  e.preventDefault();
});