$('.btn.js-add-to-watchlist').click(function () {
    var $this = $(this);
    var text = $this.text();
    $this.text('Wird für Bestellung vorgemerkt...');
    setTimeout(function () {
        $this.text(text);
    }, 3000)
});

$('.btn#go_to_order').click(function () {
    var $this = $(this);
    var text = $this.text();
    $this.text('Bestellung wird gestartet ...');
    setTimeout(function () {
        $this.text(text);
    }, 3000)
});


$('.tile').click(function () {
    var $this = $(this);
    var text = $this.children('.view_description').text();N
    $this.children('.view_description').text('Sehr gute Entscheidung ... Moment, Stück wird geöffnet.');
    setTimeout(function () {
        $this.text(text);
    }, 3000)
});