$(document).ready ->
  $('.js-hover_menu li:has(ul)').hover (->
    $(this).find('div.first-level').slideDown()
    return
  ), ->
    $(this).find('div.first-level').hide()
    return
  return

#taphover - a solution to the lack of hover on touch devices.
#more info: http://www.hnldesign.nl/work/code/mouseover-hover-on-touch-devices-using-jquery/
$('.taphover > a').on 'touchstart', (e) ->
  'use strict'
  #satisfy the code inspectors
  link = $(this)
  #preselect the link
  if link.hasClass('hover')
    true
  else
    link.addClass 'hover'
    $('a.taphover').not(this).removeClass 'hover'
    e.preventDefault()
    $(this).parent().find('div.first-level').slideDown()
    false
