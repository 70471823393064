$ ->

  $('.js-menu_burger').bind 'click', (event) =>
    event.preventDefault()
    $('.main_navigation').toggle(200)
    return false;

  (($) ->
    $.fn.jq_scrollTo = (options) ->
      settings = jQuery.extend(
        targetObject: $("body")
        scrollOffset: -10
        scrollSpeed: 800
        onBeforeScroll: ->
        onAfterScroll: ->

      , options)

      $(this).click ->
        settings.onBeforeScroll.call this
        $("body,html").animate
          scrollTop: settings.targetObject.offset().topeasytabs + settings.scrollOffset
        , settings.scrollSpeed
        settings.onAfterScroll.call this

    $.fn.scrollTo = ->
      settings =
        scrollOffset: -10
        scrollSpeed: 500
        targetObject: $(this)

      if $(this).length > 0
        $("body,html").animate
          scrollTop: settings.targetObject.offset().top + settings.scrollOffset
          , settings.scrollSpeed
        return $(this)
      else
        return null
  ) jQuery
