$(document).ready ->
  setTimeout (->
    $('.flash_message').slideDown ->
      setTimeout (->
        $('#flash_success').slideUp()
        $('#flash_notice').slideUp()
        $('#flash_error').slideUp()
        return
      ), 8000
      return
    return
  ), 600
  return

show_ajax_message = (msg, type) ->
  $('.flash').html '<div class="' + type + ' flash_message" id="flash_' + type + '">' + decodeURIComponent(escape(msg)) + '</div>'
  $('#flash_' + type).slideDown ->
    setTimeout (->
      $('#flash_success').slideUp()
      $('#flash_notice').slideUp()
      $('#flash_error').slideUp()
      return
    ), 9000
    return
  return

$(document).ready ->
  $(document).ajaxComplete (event, request) ->
    msg = request.getResponseHeader('X-Message')
    type = request.getResponseHeader('X-Message-Type')
    if msg != null
      show_ajax_message msg, type
    return
  return

$(".flash").click ->
    $(@).slideUp()
